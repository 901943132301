// @flow
import type { RawSettingsType, SettingsType } from 'types/Settings';
import { nullHighlightedPost, nullSettings } from 'types/Settings';
import { mergeIntoDefault } from 'utils/objectUtils';

export function convertSettingsFromApi(rawSettings: RawSettingsType): SettingsType {
  if (!rawSettings) {
    return nullSettings;
  }

  const {
    ligue,
    ligue_id,
    title,
    description,
    url,
    logo,
    logo_footer,
    top_news,
    actualite_une,
    liste_cards,
    class_ligue,
    titre_liste_cards,
    titre_liste_blocs_un,
    match_semaine,
    blocs_faq,
    blocs_trouver_club,
    contacts,
    favicons,
    liens_rs,
    newsletter,
    cookies,
    message_alerte,
    home,
    actualite_ffr_vers_ligues,
    partenaires,
    partenaires_page,
    partenaires_une_titre,
    partenaires_une_description,
    highlighted_post
  } = rawSettings;

  const liste_cardsMerged = liste_cards.map(liste_card => {
    return mergeIntoDefault(nullSettings.liste_cards[0], liste_card);
  });

  const gerer_clubMerged = mergeIntoDefault(nullSettings.gerer_club, rawSettings.liste_blocs_un.gros_bloc);

  const bulletin_officielMerged = mergeIntoDefault(
    nullSettings.bulletin_officiel,
    rawSettings.liste_blocs_un.petit_bloc
  );

  const blocsFaqMerged = blocs_faq.map(blocsFaq => {
    return mergeIntoDefault(nullSettings.blocs_faq[0], blocsFaq);
  });

  const blocsTrouverClubMerged = blocs_trouver_club.map(blocsTrouverClub => {
    return mergeIntoDefault(nullSettings.blocs_faq[0], blocsTrouverClub);
  });

  return mergeIntoDefault(nullSettings, {
    ligue,
    ligue_id: parseInt(ligue_id, 10),
    title,
    description,
    url,
    logo,
    logo_footer,
    top_news,
    actualite_une,
    liste_cards: liste_cardsMerged,
    class_ligue,
    titre_liste_cards,
    titre_liste_blocs_un,
    gerer_club: gerer_clubMerged,
    bulletin_officiel: bulletin_officielMerged,
    match_semaine,
    blocs_faq: blocsFaqMerged,
    blocs_trouver_club: blocsTrouverClubMerged,
    contacts,
    favicons,
    liens_rs: {
      fb_lien: liens_rs.fb_lien ? liens_rs.fb_lien : '',
      instagram_lien: liens_rs.instagram_lien ? liens_rs.instagram_lien : '',
      twitter_lien: liens_rs.twitter_lien ? liens_rs.twitter_lien : '',
      youtube_lien: liens_rs.youtube_lien ? liens_rs.youtube_lien : '',
      flickr_lien: liens_rs.flickr_lien ? liens_rs.flickr_lien : '',
      linkedin_lien: liens_rs.linkedin_lien ? liens_rs.linkedin_lien : '',
      twitch_lien: liens_rs.twitch_lien ? liens_rs.twitch_lien : '',
      snapchat_lien: liens_rs.snapchat_lien ? liens_rs.snapchat_lien : '',
      tik_tok_lien: liens_rs.tik_tok_lien ? liens_rs.tik_tok_lien : ''
    },
    newsletter,
    cookies,
    message_alerte,
    home,
    actualite_ffr_vers_ligues,
    partenaires,
    partenaires_page,
    partenaires_une_titre,
    partenaires_une_description,
    highlighted_post: Object.keys(highlighted_post).length === 0 ? nullHighlightedPost : highlighted_post
  });
}
